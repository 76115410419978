import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import setAuthHeader from "../src/auth/jwt/token"

  // Require Froala Editor js file.
  require('froala-editor/js/froala_editor.pkgd.min.js')
  
  // Require Froala Editor css files.
  require('froala-editor/css/froala_editor.pkgd.min.css')
  require('froala-editor/css/froala_style.min.css')
  
  // Import and use Vue Froala lib.
  import VueFroala from 'vue-froala-wysiwyg'
  Vue.use(VueFroala)


// import Vue from "vue"
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

import './css/tailwind.css'
import router from './router'
import store from './store'
import App from './App.vue'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueSweetalert2);


// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
if (localStorage.getItem('token')) {
  setAuthHeader(localStorage.getItem('token'))
} else {
  setAuthHeader(false)
}
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
