import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Home',
            active: true,
            requiresAuth: true
          },
        ],
      },
    },


    {
      path: 'https://app.ediqia.com/parametres',
      name: 'parametre',
    },

    // {
    //   path: 'https://app.ediqia.com/roles',
    //   name: 'role',
    // },

    {
      path: 'https://app.ediqia.com/modules',
      name: 'module',
    },

    // {
    //   path: 'https://app.ediqia.com/permissions',
    //   name: 'permission',
    // },



    {
      path: '/ticket',
      name: 'ticket',
      component: () => import('@/views/pages/ticket/index.vue'),
      meta: {
        pageTitle: 'Ticket',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Liste des tickets',
            active: true,
            requiresAuth: true
          },
        ],
      },
    },


    {
      path: '/blog',
      name: 'blog',
      component: () => import('@/views/pages/blog/index.vue'),
      meta: {
        pageTitle: 'blog',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Liste des blogs',
            active: true,
            requiresAuth: true
          },
        ],
      },
    },

    {
      path: '/message/:id',
      name: 'message',
      component: () => import('@/views/pages/ticket/message.vue'),
      meta: {
        pageTitle: 'Message',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Liste des Messages',
            active: true,
            requiresAuth: true
          },

          {
            text: 'Liste des tickets',
            to: { name: 'ticket' },
            requiresAuth: true
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/pages/user/index.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Liste des urilisateurs',
            active: true,
          },
        ],
      },
    },

    {
      path: '/newsletters',
      name: 'newsletter',
      component: () => import('@/views/pages/newsletters/index.vue'),
      meta: {
        pageTitle: 'Newsletters',
        breadcrumb: [
          {
            text: 'Liste des urilisateurs inscrire a la newsletter',
            active: true,
          },
        ],
      },
    },

    {
      path: '/categories',
      name: 'category',
      component: () => import('@/views/pages/category/index.vue'),
      meta: {
        pageTitle: 'Category',
        breadcrumb: [
          {
            text: 'Liste des categories',
            active: true,
          },
        ],
      },
    },

    
    {
      path: '/roles',
      name: 'role',
      component: () => import('@/views/pages/roles/index.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Liste des roles',
            active: true,
          },
        ],
      },
    },

    {
			path: '/permissions',
			name: 'updateRole',
			component: () => import('@/views/pages/roles/update.vue'),
			meta: {
				pageTitle: 'Roles',
				breadcrumb: [
					{
						text: 'créer un role',
					},
				]
			},
		},


    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, from, next) => {
	// Determine if the route requires authentication
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// Get value from somewhere to determine if the user is
		// logged in or not
		let isLoggedIn = false;
		if (localStorage.getItem('token')) {
			isLoggedIn = localStorage.getItem('connected');
		}

		// If user is not logged in, navigate to the named "login" route
		// with a query string parameter indicating where to navigate to after
		// successful login
		if (!isLoggedIn) {
			// Navigate to login route
			next({
				name: 'login',
				query: { redirect: to.fullPath },
			});
		} else {
			next();
		}
	} else {
		next();
	}
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
